<template>
	<div class="tooltip-trigger">
		<a href="#">
			<svg
				class="tooltip-img"
				xmlns="http://www.w3.org/2000/svg"
				xml:space="preserve"
				width="300mm"
				height="300mm"
				version="1.1"
				style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
				viewBox="0 0 30000 30000"
				xmlns:xlink="http://www.w3.org/1999/xlink"
			>
				<g>
					<path
						d="M15000 0c-7237,0 -13102,5866 -13102,13102 0,4951 2744,9254 6792,11483l-154 5415 6182 -3810c7350,167 13384,-5734 13384,-13088 0,-7236 -5866,-13102 -13102,-13102zm1668 20737l-3357 0 0 -10807 3357 0 0 10807zm-1701 -12130c-1061,0 -1768,-753 -1745,-1681 -22,-972 684,-1703 1767,-1703 1083,0 1771,731 1793,1703 -1,928 -712,1681 -1815,1681z"
					/>
				</g>
			</svg>

			подробнее</a
		>

		<div class="tooltip">
			{{ description }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		description: {
			type: String,
			default: null,
		},
	},
};
</script>
